import React, { useEffect, useState } from 'react';
import './style.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { Service } from "../../../types/types";

// Define the Discount interface
interface Discount {
  discount_name: string;
  discount_value: number;
  discount_type: 'percentage' | 'fixed';
}

interface CartProps {
  business_id: number;
  cartItems: Service[];
  business_name: string;
  rating: number;
  updateCart: (service: Service) => void;
  removeFromCart: (service: Service) => void;
  className?: string;
  is_service?: boolean;
}

const Cart: React.FC<CartProps> = ({
  business_id,
  business_name,
  rating,
  cartItems,
  updateCart,
  removeFromCart,
  className,
  is_service,
}) => {
  const [userIdExists, setUserIdExists] = useState<boolean>(false);
  const [discount, setDiscount] = useState<Discount | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getUserIdFromCookie = () => {
      return document.cookie.includes('userId');
    };

    setUserIdExists(getUserIdFromCookie());

    // Fetch discount for the current business
    const fetchDiscount = async () => {
      try {
        const response = await fetch('/api/discounts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ business_id }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch discount');
        }

        const data: Discount = await response.json();
        setDiscount(data);
      } catch (err: any) {
        console.error('Error fetching discount:', err);
        setError(err.message);
      }
    };

    fetchDiscount();
  }, [business_id]);

  const renderStars = (rating: number) => {
    const stars = [];
    const roundedRating = Math.floor(rating);

    // Filled stars
    for (let i = 0; i < roundedRating; i++) {
      stars.push(
        <FontAwesomeIcon
          key={i}
          icon={faStar}
          className="text-black text-sm"
        />
      );
    }

    // Half star for fractional ratings
    if (roundedRating < rating) {
      stars.push(
        <FontAwesomeIcon
          key="half-star"
          icon={faStarHalfAlt}
          className="text-black text-sm"
        />
      );
    }

    // Empty stars
    for (let i = stars.length; i < 5; i++) {
      stars.push(
        <FontAwesomeIcon
          key={`empty-star-${i}`}
          icon={faStar}
          className="text-black text-sm opacity-10"
        />
      );
    }

    return <span className="ml-1">{stars}</span>;
  };

  const setCookie = () => {
    const expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + 3 * 60 * 60 * 1000); // 3 hours
    document.cookie = `onBooking=true; expires=${expireDate.toUTCString()}; path=/`;
  };

  const calculateTotal = () => {
    const total = cartItems.reduce((sum, item) => sum + parseFloat(item.price), 0);

    if (discount) {
      if (discount.discount_type === 'percentage') {
        return total - (total * discount.discount_value) / 100;
      } else if (discount.discount_type === 'fixed') {
        return Math.max(total - discount.discount_value, 0);
      }
    }

    return total;
  };

  const handleBookNow = () => {
    if (userIdExists) {
      localStorage.setItem('cartData', JSON.stringify({ business_id, cartItems }));
      window.location.href = '/booking';
    } else {
      localStorage.setItem('cartData', JSON.stringify({ business_id, cartItems }));
      setCookie();
      window.location.href = '/login';
    }
  };

  return (
    <div className={`w-[100%] ${className}`}>
      <h2 className="text-xl flex sm:text-xl lg:text-xl font-bold mb-4">
        {business_name}
      </h2>
      <p className={`text-black font-bold `} style={{ marginTop: '-15px' }}>
        {renderStars(rating)}
      </p>
      <h3 className="text-l sm:text-l lg:text-l font-semibold mb-4">
        Book Appointment
      </h3>

      {discount && (
        <div className="bg-green-100 text-green-700 p-2 rounded-md mb-4">
          <span className="font-semibold">Discount Applied:</span> {discount.discount_name} - {discount.discount_value}
          {discount.discount_type === 'percentage' ? '%' : '₹'}
        </div>
      )}
      {cartItems.length > 0 ? (
        <>
          <div className="cartItem">
            <div className="font-semibold">Service</div>
            <div className="font-semibold">Price(₹)</div>
          </div>

          {cartItems.map((item) => (
            <div key={item.id}>
              <div className="cartItem">
                <div>
                  {item.name}
                  <span className="text-sm p-3">({item.duration}min)</span>
                </div>
                <div>{item.price}</div>
                <button
                  className="bg-white font-extrabold"
                  onClick={() => removeFromCart(item)}
                >
                  <span className="font-extrabold text-red-400 text-xl">×</span>
                </button>
              </div>
              <hr style={{ width: '100%', margin: '5px 0' }} />
            </div>
          ))}

          <div className="cartItem">
            <div className="font-semibold">Total</div>
            <div className="font-semibold">{calculateTotal().toFixed(2)}</div>
          </div>
          <button
            className={`bookNowButton`}
            onClick={handleBookNow}
            style={{ backgroundColor: '#de6364' }}
          >
            Book Now
          </button>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center space-y-4">
          <p className="text-lg font-semibold">Please select a service</p>
        </div>
      )}
      {is_service === false && (
        <button
          className="bookNowButton px-6 py-2 text-white rounded-md font-medium transition duration-300 ease-in-out transform hover:scale-105"
          onClick={handleBookNow}
          style={{ backgroundColor: '#de6364' }}
        >
          Book Quick Appointment
        </button>
      )}
    </div>
  );
};

export default Cart;
