import React from "react";

const TermsCondition: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-50 text-gray-800">
      <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>
      <p className="mb-4">
        By using the website <a href="http://www.Feelaxo.com" className="text-blue-600 underline">http://www.Feelaxo.com</a> (the “website” or “Feelaxo.com” in this document), you, the customer (“you”, “your”, or the “user” in this document) unconditionally agree to the terms and conditions that we at, Feelaxo.com with our registered office at Flat-601, Building-2, KH4, Celebration, Kharghar, Sector-17, Navi
Mumbai, RAIGAD, MAHARASHTRA, 410210
 (“Feelaxo.com”, “we” or “us” or “our” in this document) have provided herein for use of Feelaxo’s website or mobile application owned and maintained by us.
      </p>
      <p className="mb-4">
        This website or mobile application and any individual websites or merchant-specific, city-specific, or state-specific sites now or hereinafter contained within or otherwise available through external hyperlinks (“minisites”) with our website or mobile application are private property. All interactions on this website or mobile application must comply with these terms of use. If you do not wish to agree to the outlined terms and conditions (the “terms of use” in this document), please refrain from using this website or mobile application.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-4">General Terms & Conditions</h2>
      <ol className="list-decimal list-inside space-y-4">
        <li>
          <strong>Platform Usage:</strong> Feelaxo.com acts as an intermediary platform connecting service providers (salons, spas, and wellness centers) with customers. Both service providers and customers must agree to abide by these terms when using the platform.
        </li>
        <li>
          <strong>Service Listings:</strong> Service providers are responsible for accurately listing their services, prices, and availability. If you find any business listed with incorrect information, contact us by email. If you own a business listed on the platform, feel free to claim your listing to get admin access.
        </li>
        <li>
          <strong>Appointments:</strong> Customers can book services directly through the platform. Appointment changes or cancellations must comply with the individual service provider’s policies.
        </li>
        <li>
          <strong>Payments & Refunds:</strong> Refund will be creditted to the orginial paymend method within 48 working hours. Payments for services may be processed through the platform or directly by the service provider. Price should be starting from Rs.100 to 5000 as per the services offerd by listed business Refunds or disputes must be resolved between the customer and service provider, with the platform offering mediation where necessary. 100% refund will be provided if the customer cancels the placed service 8 hour before the booking scheduled time
        </li>
        <li>
          <strong>Responsibilities:</strong> Service providers are solely responsible for the delivery and quality of services. The platform is not liable for issues arising from service delivery.
        </li>
        <li>
          <strong>Customer Reviews:</strong> Customers can leave reviews for services they have availed. Reviews must be honest, respectful, and not violate any community guidelines.
        </li>
        <li>
          <strong>Privacy & Data:</strong> Users’ data is collected, stored, and processed in line with the platform’s Privacy Policy.
        </li>
        <li>
          <strong>Commission & Fees:</strong> The platform may charge service providers a subscription fee or commission on bookings, as specified during registration.
        </li>
        <li>
          <strong>Termination of Accounts:</strong> The platform reserves the right to suspend or terminate user accounts for violations of these terms.
        </li>
        <li>
          <strong>Amendments:</strong> The platform can update these terms at its discretion, with changes communicated to users.
        </li>
      </ol>

      <h2 className="text-xl font-semibold mt-6 mb-4">Contact Us</h2>
      <p className="mb-2">
        In accordance with the Information Technology Act 2000 and rules made thereunder, if you have any questions regarding our Privacy Policy, Terms of Sale, or Terms of Use, you may contact our Grievance Officer at the details below:
      </p>
      <p className="mb-2">
        <strong>Address:</strong> Welltech Lifestyle, Flat-601, Building-2, KH4, Celebration, Kharghar, Sector-17, Navi Mumbai, RAIGAD, MAHARASHTRA, 410210

      </p>
      <p>
        <strong>Email:</strong> <a href="mailto:hello@feelaxo.com" className="text-blue-600 underline">hello@feelaxo.com</a>
      </p>
    </div>
  );
};

export default TermsCondition;
