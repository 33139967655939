import React, { useEffect, useState } from "react";
import axios from "axios";
import SuccessPage from "./SuccessPage";
import { FaWhatsapp } from "react-icons/fa";
import {UserData, Staff, TaxInfo, Service} from "../../../types/types";

interface ConfirmBookingProps {
  cartItems: Service[];
  businessId: number;
  selectedDate: Date | null;
  selectedTime: string;
  cartTotal: string;
  selectedStaffInfo: Staff | null;
  paymentId: string;
  paymentMethod: string;
  couponCode: string | null;
  handleClose: () => void;
  taxInfo: TaxInfo | null;
  popup?: boolean;
  SendOtpEvent?: boolean;
  changeSendOtpEvent?: (value: boolean) => void;
  setBlack?: (value: boolean) => void;
}

const ConfirmBookingPage: React.FC<ConfirmBookingProps> = ({
  cartItems,
  businessId,
  selectedDate,
  cartTotal,
  selectedTime,
  selectedStaffInfo,
  paymentId,
  paymentMethod,
  couponCode,
  handleClose,
  taxInfo,
  popup,
  SendOtpEvent,
  changeSendOtpEvent,
  setBlack
}) => {
  const [loading, setLoading] = useState(true);
  const [appointmentId, setAppointmentId] = useState<number>(0);
  const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [otpVerifying, setOtpVerifying] = useState<boolean>(false);

  const startOfDay = (date: Date): Date => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value.replace(/\D/g, "").slice(0, 1);
    setOtp(newOtp);

    // Move focus to next input if value is added
    if (value && index < 5) {
      const nextInput = document.getElementById(`otpInput-${index + 1}`);
      if (nextInput) nextInput.focus();
    }
    if(index == 5) {
      if(setBlack) {
        setBlack(true)
      }
    }
    if(index < 5) {
      if(setBlack) {
        setBlack(false)
      }
    }
  };

  const handleOtpKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        const previousInput = document.getElementById(`otpInput-${index - 1}`);
        if (previousInput) previousInput.focus();
      }
    }
  };

  const readCookie = (cookieName: string): string | null => {
    const nameEQ = cookieName + "=";
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  };

  const sendBookingConfirmation = async (userId: string) => {
    try {


      if(selectedDate != null) {
        const normalizedDate = startOfDay(selectedDate);
        const utcDate = new Date(Date.UTC(
          normalizedDate.getFullYear(),
          normalizedDate.getMonth(),
          normalizedDate.getDate()
        ));

        const response = await axios.post(
          "/api/order/book",
          {
            userId,
            cartItems,
            businessId,
            selectedDate: utcDate.toISOString(),
            cartTotal,
            selectedTime,
            selectedStaffInfo,
            paymentId,
            paymentMethod,
            couponCode,
            taxInfo,
          },
          {
            headers: {
              "Content-Type": "application/json",
            }
          }
        );
  
        const data = await response.data;
        setAppointmentId(data.appointment_id);
        setLoading(false);
      }

      


    } catch (error) {
      console.error("Error confirming booking:", error);
      setLoading(false);
      if (changeSendOtpEvent) {
        changeSendOtpEvent(false);
      }
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    const userId = readCookie("userId");

    const sendConfirmation = async () => {
      if (userId) {
        await sendBookingConfirmation(userId);
      }
    };

    sendConfirmation();

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const sendOtp = async () => {
    if (otp.length < 6) {
      return
    }

    setOtpVerifying(true);
    try {
      const response = await axios.post("/api/order/verify/otp", {
        otp: otp.join(""),
        appointmentId,
      });

      const data = response.data;

      if (data.message === "Error verifying OTP") {
        setError("Unable to verify OTP, please try again later.");
        console.log("Error")
        if(changeSendOtpEvent) {
          changeSendOtpEvent(false)
        }

      } else if (data.message === "OTP not verified") {
        setError("Please enter correct OTP.");
        console.log("error")
        if(changeSendOtpEvent) {
          changeSendOtpEvent(false)
        }
      } else if (data.success === true) {
        setSuccess(true);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
    setOtpVerifying(false);
  };


  useEffect(() => {
    if(SendOtpEvent) {
      sendOtp()
    }
  }, [SendOtpEvent])

  const userId = readCookie("userId");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post("/api/customer/post/customer", {
          userId: userId,
        });

        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  return (
    <>
      {loading || otpVerifying ? (
       <div className=" fixed top-0 left-0 w-full h-full bg-opacity-70">
        <div className="fixed top-1/2 transform z-50 bg-white w-[100%] xl:w-[75%]  flex flex-col items-center justify-center">
          <div className="animate-spin block rounded-full h-20 w-20 border-t-4 border-red-500 border-opacity-75 mb-4"></div>
          <div className="text-gray-700 text-lg font-semibold drop-shadow-sm">Please wait...</div>
        </div>

     </div>
     
      ) : (
        <>
            {popup ? (
              <>

                <div className="border fixed top-0 left-0 w-full h-full bg-black bg-opacity-70" style={{ zIndex: 50 }} />
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-96 p-6 shadow-lg rounded-lg">
                  <button className="absolute top-3 right-3 text-gray-600 hover:text-red-500 text-2xl" onClick={handleClose}>
                    &times;
                  </button>
                  <div className="border text-lg text-center text-gray-800 mt-6 font-semibold">
                    Please confirm your order using the WhatsApp OTP sent to the number 
                    <span className="inline-flex text-center items-center ml-2">
                      <FaWhatsapp className="text-green-600 font-bold text-lg mr-1" /> 
                      {userData && userData.phone}
                    </span>
                  </div>
                  <div className="text-red-500 text-center mt-4">
                    {error && <div>{error}</div>}
                  </div>
                  <div className="flex justify-center gap-2 mt-4">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        id={`otpInput-${index}`}
                        name={`otp-${index}`}
                        value={digit}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleOtpKeyDown(e, index)}
                        maxLength={1}
                        className="w-10 p-2 rounded-sm text-center focus:outline-none focus:border-b-blue-400"
                        autoFocus={index === 0}
                      />
                    ))}
                  </div>
                  <button 
                    onClick={sendOtp} 
                    className="bg-red-400 rounded-full text-white w-full px-4 py-2  mt-6 hover:bg-black transition-colors"
                  >
                    Confirm Booking
                  </button>
                </div>
                </>
            
          ) : (
              <div className="p-4 bg-gray-100 rounded-xl items-center justify-center w-80 mx-auto my-auto mt-44">
                <div className="text-lg text-center text-gray-800 mt-6 font-semibold">
                  Please confirm your order using the WhatsApp OTP sent to the number 
                  <span className="inline-flex text-center items-center ml-2">
                    <FaWhatsapp className="text-green-600 font-bold text-lg mr-1" /> 
                    {userData && userData.phone}
                  </span>
                </div>
                <div className="text-red-500 text-center mt-4">
                  {error && <div>{error}</div>}
                </div>
                <div className="flex justify-center gap-2 mt-4">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      id={`otpInput-${index}`}
                      name={`otp-${index}`}
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                      maxLength={1}
                      className="w-10 p-2 rounded-sm text-center focus:outline-none focus:border-b-blue-400"
                      autoFocus={index === 0}
                    />
                  ))}
                </div>
              </div>
          )}
          {success && <SuccessPage />}
        </>
      )}
    </>
  );
};

export default ConfirmBookingPage;
