    import React, { useState, useEffect } from 'react';
    import axios from "axios";
    import { BusinessData } from '../../types/types';
    import './style.css';
    import ConfirmBookingPage from 'containers/Booking/components/Booking';
    import AddReview from "containers/AccountPage/AddReview";
    import { StarIcon } from "@heroicons/react/24/solid";
    import {Service, TaxInfo, Staff, Rating, FrontendData} from "../../types/types";
    import userImage from "./user.png"; 
    import { FaPhoneAlt } from 'react-icons/fa';

    interface OrderProps {
        appointment_id: string;
        appointmentDate: Date;
        appointmentTime: string;
        itemsSelected: Array<Service>;
        cartTotal: string;
        paymentId: string;
        paymentType: string;
        otp: number;
        invoice: string;
        status: string;
        business_id: number;
        staffMemberId: number;
        couponCode: string;
    }
    interface OrderDetailsProps {
        orderDetails: OrderProps | null,
        closeDetails: () => void;
    }

    

    const formatDate = (dateString: Date) => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const formatTime = (timeString: string) => {
        const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return new Date(`2000-01-01T${timeString}`).toLocaleTimeString('en-US', options);
    };

    const getStatusColor = (status: string | undefined): string => {
        switch (status) {
            case 'confirmed':
                return 'bg-blue-200 border border-blue-400 text-blue-500';
            case 'booked':
                return 'bg-blue-200 border border-blue-400 text-blue-500';
            case 'completed':
                return 'bg-green-200 border border-green-400 text-green-500';
            case 'pending':
                return 'bg-yellow-200 border border-yellow-500 text-yellow-500';
            case 'cancelled':
                return 'bg-red-200 border border-red-400 text-red-500';
            default:
                return 'bg-blue-200 border border-blue-400 text-blue-500';
        }
    }

    const OrderDetails: React.FC<OrderDetailsProps> = ({ orderDetails, closeDetails }) => {
        const [businessDetail, setBusinessDetail] = useState<BusinessData | null>(null);
        const [confirmBooking, setConfirmBooking] = useState(false);
        const [selectedStaff, setselectedStaff] = useState<Staff | null>(null);
        const [cancleOrderVar, setcancleOrder] = useState(false);
        const [TaxInfo, setTaxInfo] = useState<TaxInfo | null>(null);
        const [VarRating, setRating] = useState<Rating | null>(null);
        const [cityData, setCityData] = useState<FrontendData | null>(null);


        useEffect(() => {
            getCityDataFromCookie();
        }, []);
    
        // Function to get cityData from cookie
        const getCityDataFromCookie = () => {
            const getCookie = (cookieName: string): string | null => {
                const name = `${cookieName}=`;
                const decodedCookie = decodeURIComponent(document.cookie);
                const cookieArray = decodedCookie.split(";");
    
                for (let i = 0; i < cookieArray.length; i++) {
                    let cookie = cookieArray[i].trim();
                    if (cookie.indexOf(name) === 0) {
                        return cookie.substring(name.length, cookie.length);
                    }
                }
    
                return null;
            };
    
            const frontendDataCookie: FrontendData | null = JSON.parse(getCookie("frontendData") || "null");
    
            if (frontendDataCookie) {
                setCityData(frontendDataCookie);
            }
        };


        //review page
        const [ReviewPageState, SetReviewPageState] = useState<number>();

        useEffect(() => {
            const fetchData = async () => {
                if (orderDetails !== null) {
                    try {
                        const response = await fetch("/api/business/post/userId", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                id: orderDetails.business_id,
                            }),
                        });

                        if (!response.ok) {
                            throw new Error("Failed to fetch data");
                        }

                        const data: BusinessData = await response.json();
                        setBusinessDetail(data);
                    } catch (error) {
                        console.error("Error fetching business data:", error);
                    }

                    try {
                        const response = await fetch(`/api/business/get/staff/id/${orderDetails.staffMemberId}`, {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                            },
                        });

                        if (!response.ok) {
                            throw new Error("Failed to fetch data");
                        }

                        const data: Staff = await response.json();
                        setselectedStaff(data);
                    } catch (error) {
                        console.error("Error fetching staff data:", error);
                    }
                }
            };

            if (orderDetails !== null) {
                fetchData();
            }
        }, [orderDetails]);

        const cancelOrder = async () => {
            try {
                if (orderDetails?.appointment_id) {
                    const response = await fetch(`/api/order/cancle/${orderDetails.appointment_id}`);

                    if(response.ok) {
                        alert("Order cancelled Successfully!");
                        window.location.reload();
                    }
                    
                    if (!response.ok) {
                        throw new Error(`Failed to cancel order. Status: ${response.status}`);
                    }
                } else {
                    console.log("No appointment ID available for cancellation.");
                }
            } catch (error) {
                console.error("Error during order cancellation:", error);
            }
        };

        const closeReview = () => {
            SetReviewPageState(undefined);
            callReview();
        }


        useEffect(() => {
            callReview();
        }, [])

        const callReview = () => {
            if(orderDetails) {
                axios.post('/api/business/rating/get', {
                    orderId: orderDetails.appointment_id
                }).then((response) => {
                    setRating(response.data);
                }).catch((error) => {
                    console.log(error);
                })
            }
        }


        const makeSEOUrl = (businessName: string, businessId: number, businessType: number) => {
            let url = `/venue/`;
            let bType: string = '';
        
            if (businessType === 1) {
                bType = "spa";
            } else if (businessType === 2) {
                bType = "salon";
            } else if (businessType === 3) {
                bType = "wellness";
            } else {
                try {
                    axios.post("/api/business/post/business/type", { businessId }).then((response) => {
    
                    bType = response.data
                    })
                } catch (error) {
                    console.error(error);
                }
            }

        
            const sanitizedCityName = cityData?.city_name.replace(/\s/g, "-");
        
            const sanitizedBusinessName = businessName.replace(/\s/g, "-");

        
            url = `${url}${bType}/${sanitizedCityName}/${sanitizedBusinessName}?id=${businessId}`;
        
            return url;
        }

        return (
            <>
                <div onClick={closeDetails} className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50" style={{ zIndex: 47 }} />
                <div className="bg-white fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 shadow-xl p-4 rounded-lg border overflow-y-auto max-h-[600px] mt-5 scrollbar" style={{ zIndex: 48 }}>
                    <div className="grid" style={{ display: 'flex', marginTop: '20px', marginBottom: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className="text-lg font-bold text-left inline-block">Order Details</h2>
                        <button
                            className="text-2xl hover:text-red-500 font-thin text-right inline-block"
                            onClick={closeDetails}
                        >
                            ×
                        </button>
                    </div>
                    <div>
                        {orderDetails != null && (
                            <>
                                {businessDetail && (
                                    <>
                                        <p><strong>{businessDetail.business_name}</strong></p>
                                        <p className="flex items-center gap-2"><FaPhoneAlt /><strong>{businessDetail.phone}</strong></p>
                                        <p>{businessDetail.address}</p>
                                        <a href={makeSEOUrl(businessDetail.business_name, businessDetail.id, businessDetail.business_type)} className="text-blue-500 underline block mb-4">View Salon</a>
                                    </>
                                )}
                               {selectedStaff != null ? (
                                    <div className="mx-auto mt-5 mb-5 w-80 bg-purple-200 border border-purple-500 rounded-md drop-shadow-lg" style={{ display: "grid", gridTemplateColumns: "1fr 8fr", alignItems: "center" }}>
                                        <div>
                                            <div className="bg-cover bg-center ml-5 mr-5 rounded-full drop-shadow-lg" style={{ width: "40px", height: "40px", backgroundImage: `url('${selectedStaff?.profile}')` }}></div>
                                        </div>
                                        <div className="grid" style={{ gridTemplateRows: "auto auto" }}>
                                            <p className="text-purple-500"><strong>{selectedStaff?.name}</strong></p>
                                            <p className="font-thin text-gray-200">{selectedStaff?.job_title}</p>
                                        </div>  
                                    </div>
                                ) : (selectedStaff == null || orderDetails.staffMemberId == 0) ? (
                                    <div className="mx-auto mt-5 mb-5 w-80 bg-purple-200 border border-purple-500 rounded-md drop-shadow-lg" style={{ display: "grid", gridTemplateColumns: "1fr 8fr", alignItems: "center" }}>
                                        <div>
                                            <div className="bg-cover bg-center ml-5 mr-5 rounded-full drop-shadow-lg" style={{ width: "40px", height: "40px", backgroundImage: `url('${userImage}')` }}></div>
                                        </div>
                                        <div className="grid" style={{ gridTemplateRows: "auto auto" }}>
                                            <p className="text-purple-500"><strong>Any Professional</strong></p>
                                            
                                        </div>  
                                    </div>
                                ) : null}

                                <p>Order ID: {orderDetails.appointment_id} <div className={`inline-block rounded-md float-right text-center p-2 ${getStatusColor(orderDetails?.status)}`}>{orderDetails.status}</div></p>
                                <p>Date: {formatDate(orderDetails.appointmentDate)}</p>
                                <p>Time: {formatTime(orderDetails.appointmentTime)}</p>
                                <p>Verification OTP: <strong>{orderDetails.otp}</strong></p>
                                <p className='mt-5 mb-5'><strong>Cart Items</strong></p>
                                <table className="table-auto w-full mb-5">
                                    <thead>
                                        <tr>
                                            <th className="border px-4 py-2">Service Name</th>
                                            <th className="border px-4 py-2">Price (₹)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderDetails.itemsSelected.map((service, index) => (
                                            <tr key={index}>
                                                <td className="border px-4 py-2 text-center">{service.name}</td>
                                                <td className="border px-4 py-2 text-center">{service.price}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className="border px-4 py-2 text-center"><strong>Grand Total</strong></td>
                                            <td className="border px-4 py-2 text-center"><strong>₹{orderDetails.cartTotal}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>Payment Method: <strong>{orderDetails.paymentType}</strong></p>
                                <p>Payment ID: <strong>{orderDetails.paymentId}</strong></p>
                                {(orderDetails.invoice != null) && (
                                    <a href={orderDetails.invoice} className="text-blue-500 hover:underline block mb-4">Download Invoice</a>
                                )}
                                {(orderDetails.status === 'pending') && (
                                    <p>Order yet to confirm</p>
                                )}
                                <div>
                                    {orderDetails.status === 'confirmed' && (
                                        <div className="flex justify-center">
                                            <button onClick={() => {setcancleOrder(true)}} className="w-80 bg-red-500 text-white rounded p-2">Cancel Order</button>
                                        </div>
                                    )}
                                    {orderDetails.status === 'pending' && (
                                        <div className="flex justify-center">
                                            <button onClick={() => {setConfirmBooking(true)}} className="w-80 bg-blue-500 text-white rounded p-2">Confirm Booking</button>
                                        </div>
                                    )}
                                    {orderDetails.status === 'completed' && (
                                        <>
                                            {VarRating != null ? (
                                                <div>
                                                    <div className="font-bold text-lg">Your review</div>
                                                    <div className="stars inline">
                                                        {[...Array(VarRating.rating)].map((_, index) => (
                                                            <div className="text-yellow-400 inline" key={index} style={{ color: index < VarRating.rating ? '' : 'gray' }}>
                                                                <StarIcon className="w-4 h-4 inline" />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="description">{VarRating.description}</div>
                                                    <div className="added_on text-gray-500">added on: {VarRating.added_on}</div>
                                                </div>
                                            ) : (
                                            <div className="flex justify-center">
                                                <button onClick={() => {SetReviewPageState(businessDetail?.id)}} className="w-80 bg-blue-500 text-white rounded p-2">Rate Your Experience</button>
                                            </div>
                                            )}
                                        </>
                                    )}

                                </div>
                            </>
                        )}
                    </div>
                </div> 
                {confirmBooking && businessDetail != null && orderDetails != null && (
                    <ConfirmBookingPage
                        cartItems={orderDetails.itemsSelected}
                        businessId={businessDetail.id}
                        selectedDate={orderDetails.appointmentDate}
                        selectedTime={orderDetails.appointmentTime}
                        cartTotal={orderDetails.cartTotal}
                        selectedStaffInfo={selectedStaff}
                        paymentId={orderDetails.paymentId}
                        paymentMethod={orderDetails.paymentType}
                        couponCode={orderDetails.couponCode}
                        handleClose={() => setConfirmBooking(false)}
                        taxInfo={TaxInfo}
                        popup={true}
                    />
                )}
                {cancleOrderVar && orderDetails != null && (
                    <>
                        <div onClick={() => {setcancleOrder(false)}} className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50" style={{ zIndex: 48 }} />
                        <div className="bg-white fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-96 shadow-xl p-4 rounded-lg border overflow-y-auto max-h-[600px] mt-5 scrollbar">
                            <div className="grid" style={{ display: 'flex', marginTop: '20px', marginBottom: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h2 className="text-lg font-bold text-left inline-block">Cancel Order?</h2>
                                <button
                                    className="text-2xl hover:text-red-500 font-thin text-right inline-block"
                                    onClick={() => {setcancleOrder(false)}}
                                >
                                    ×
                                </button>
                            </div>
                            <div>
                                <div className="flex justify-center">
                                    <button onClick={() => {setcancleOrder(false)}}className="w-80 bg-green-500 text-white rounded p-2">No, Go Back</button>
                                </div>
                                <p className="text-center">By clicking on cancel order you are agreeing to our refund policy. Your refund will take 72 hours to process. We will contact you on your registered mobile number regarding the refund.</p>
                                <div className="flex justify-center">
                                    <button onClick={() => {cancelOrder()}}className="mt-5 w-80 bg-red-500 text-white rounded p-2">Yes, Cancel Order</button>
                                </div>
                            </div>
                        </div>
                    </>
                )}  



                {
                    ReviewPageState && (
                        <AddReview businessId={businessDetail?.id} orderId={orderDetails?.appointment_id} closeReview={closeReview} />
                    )
                }
            </>
        );
    };

    export default OrderDetails;
